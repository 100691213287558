import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "users" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_tab = _resolveComponent("md-tab")!
  const _component_md_tabs = _resolveComponent("md-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_md_tabs, { title: "Einstellungen" }, {
      default: _withCtx(() => [
        _createVNode(_component_md_tab, {
          title: "Verhalten",
          icon: "psychology",
          name: "BotSettingsBehavior"
        }),
        _createVNode(_component_md_tab, {
          title: "Sprachen",
          icon: "language",
          name: "BotSettingsLanguages"
        }),
        _createVNode(_component_md_tab, {
          title: "Öffentliche Profile",
          icon: "badge",
          name: "BotSettingsPublicProfiles"
        }),
        _createVNode(_component_md_tab, {
          title: "Posteingang",
          icon: "inbox",
          name: "BotSettingsInbox"
        }),
        _createVNode(_component_md_tab, {
          title: "API",
          icon: "api",
          name: "BotSettingsAPI"
        })
      ]),
      _: 1
    })
  ]))
}