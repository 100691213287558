import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f4e6c5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "category"
}
const _hoisted_2 = ["draggable", "onDragstart", "onClick"]
const _hoisted_3 = {
  key: 0,
  class: "icon"
}
const _hoisted_4 = { class: "material-icons-round" }
const _hoisted_5 = { class: "name" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "expand" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_button = _resolveComponent("md-button")!
  const _component_md_tooltip = _resolveComponent("md-tooltip")!

  return (_openBlock(), _createElementBlock("ul", {
    class: "steps-list",
    onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onScroll && _ctx.onScroll(...args)))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, k) => {
      return (_openBlock(), _createElementBlock("li", { key: k }, [
        (step.isCategory)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("span", null, _toDisplayString(step.name), 1)
            ]))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["step", { clickable: !_ctx.draggable }]),
              draggable: _ctx.draggable,
              onDragstart: ($event: any) => (_ctx.onWrapperDragStart($event, step.type)),
              onClick: ($event: any) => (_ctx.onClick($event, step))
            }, [
              (step.icon)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("i", _hoisted_4, _toDisplayString(step.icon), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h4", null, _toDisplayString(step.name), 1),
                (step._expanded)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(step.description), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_7, [
                (step.description)
                  ? (_openBlock(), _createBlock(_component_md_tooltip, {
                      key: 0,
                      position: "top",
                      text: "Info"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_md_button, {
                          variant: "icon",
                          icon: "expand_more",
                          grey: "",
                          mode: "icon_small",
                          onClick: ($event: any) => (step._expanded = step._expanded ? false : true),
                          class: _normalizeClass({ expanded: step._expanded })
                        }, null, 8, ["onClick", "class"])
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ])
            ], 42, _hoisted_2))
      ]))
    }), 128))
  ], 32))
}