
import { defineComponent } from "vue";
import { Handle } from "@braks/vue-flow";
import FlowNodeWrapper from "@/components/bot/FlowNodeWrapper.vue";

export default defineComponent({
  name: "FlowNodeIntent",
  components: {
    Handle,
    FlowNodeWrapper,
  },
  props: {
    data: Object,
  },
});
