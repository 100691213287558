
import { defineComponent } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { useStore } from "@/store";
import { MdInputField } from "@/components/md/MdInput";
import { BotStore, RootStore } from "@/store/constants";
import { createTelegramChannel } from "@/api/bot/channels";

export default defineComponent({
  name: "ChannelsTelegramCreateModal",
  mixins: [MdModalComponentMixin],
  components: {
    MdModal,
    MdButton,
    MdInputField,
  },
  data() {
    return {
      botToken: "",
    };
  },
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    chatbotId(): string {
      return this.store.getters[BotStore.Getters.GET_ID];
    },
  },
  methods: {
    async createTelegramBot() {
      this.setLoadingState(true);
      try {
        await createTelegramChannel(this.chatbotId, this.botToken);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "success",
          content: "Telegram Bot hinzugefügt",
        });
      } catch (err) {
        console.error(err);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Telegram Bot nicht gefunden",
        });
      }
      this.setLoadingState(false);
      this.close();
    },
  },
});
