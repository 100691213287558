
import { defineComponent, PropType } from "vue";
import { IMenuItem, MdMenu } from "@/components/md/MdMenu";

export default defineComponent({
  name: "FlowPanel",
  components: {
    MdMenu,
  },
  emits: ["select"],
  props: {
    x: {
      type: Number,
      required: true,
    },
    y: {
      type: Number,
      required: true,
    },
    items: {
      type: Array as PropType<IMenuItem[]>,
      required: true,
    },
    modelValue: String,
  },
  data() {
    return {
      value: "",
    };
  },
  methods: {
    onSelect(value, item) {
      this.$emit("select", value, item);
    },
  },
});
