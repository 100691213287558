
import { defineComponent, PropType } from "vue";
import { MdTooltip } from "@/components/md/MdTooltip";
import { MdButton } from "@/components/md/MdButton";

export default defineComponent({
  name: "InputActions",
  components: {
    MdTooltip,
    MdButton,
  },
  props: {
    target: Object as PropType<HTMLElement>,
    content: String,
    maxCharacters: Number,
    translate: Boolean,
  },
  data() {
    return {
      width: 0,
    };
  },
  mounted() {
    if (this.$refs.actions)
      this.width = (this.$refs.actions as HTMLElement).offsetWidth;
  },
  computed: {
    // x(): number {
    //   if(!this.target) return 0;
    //   return this.target.getBoundingClientRect().left + window.scrollX + this.target.offsetWidth - this.width;
    // },
    x(): number {
      if (!this.target) return 0;
      return this.target.offsetWidth - this.width * 3;
    },
    // y(): number {
    //   if(!this.target) return 0;
    //   return this.target.getBoundingClientRect().top + window.scrollY + this.target.offsetHeight + 1;
    // },
    y(): number {
      if (!this.target) return 0;
      return this.target.offsetHeight + 1;
    },
    remainingChracters(): number | boolean {
      if (!this.maxCharacters) return false;
      return this.maxCharacters - (this.content || "").length || 0;
    },
    counterClassName(): string {
      if (this.remainingChracters === false) return "";
      const factor =
        (this.remainingChracters as number) / (this.maxCharacters || 1);
      if (factor <= 0.1 || this.remainingChracters == 0) return "red";
      if (factor <= 0.3) return "orange";
      return "green";
    },
  },
});
