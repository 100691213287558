
import { defineComponent, PropType, ref } from "vue";
import { MdCard } from "@/components/md/MdCard";
import { MdTooltip } from "@/components/md/MdTooltip";
import { MdButton } from "@/components/md/MdButton";
import { MdInputField } from "@/components/md/MdInput";
import FlowPanelContentEntry from "@/components/bot/FlowPanelContentEntry.vue";
import FlowPanelContentBlock from "@/components/bot/FlowPanelContentBlock.vue";
import FlowPanelContentDelay from "@/components/bot/FlowPanelContentDelay.vue";
import FlowPanelContentSplit from "@/components/bot/FlowPanelContentSplit.vue";
import FlowPanelContentIntent from "@/components/bot/FlowPanelContentIntent.vue";
import FlowPanelContentCondition from "@/components/bot/FlowPanelContentCondition.vue";
import FlowPanelContentFlow from "@/components/bot/FlowPanelContentFlow.vue";
import FlowPanelContentNote from "@/components/bot/FlowPanelContentNote.vue";
import FlowPanelContentOutlet from "@/components/bot/FlowPanelContentOutlet.vue";
import { IBlock } from "@chatcaptain/types/chatbot";
import { GraphNode } from "@braks/vue-flow";
import { TransitionFade } from "@/components/cc/Transition";
import { IFlowPanelContent } from "@/components/bot/FlowPanelContentMixin";
import { useStore } from "@/store";
import { RootStore } from "@/store/constants";
import { deleteFlowStep } from "@/utils/FlowNodes";

export interface IFlowPanel {
  close(): void;
}

export default defineComponent({
  name: "FlowPanel",
  components: {
    MdCard,
    MdTooltip,
    MdButton,
    MdInputField,
    FlowPanelContentEntry,
    FlowPanelContentBlock,
    FlowPanelContentDelay,
    FlowPanelContentSplit,
    FlowPanelContentIntent,
    FlowPanelContentCondition,
    FlowPanelContentFlow,
    FlowPanelContentNote,
    FlowPanelContentOutlet,
    TransitionFade,
  },
  props: {
    node: Object as PropType<GraphNode<IBlock>>,
    large: Boolean,
  },
  emits: ["close", "update:node", "delete", "duplicate"],
  setup() {
    return {
      store: useStore(),
    };
  },
  data() {
    return {
      block: undefined as IBlock | undefined,
      name: "",
      description: "",
      editDescription: false,
      scrollTop: false,
      showName: false,
      scrollBottom: true,
      showOverlay: false,
      copyDisabled: false,
      deleteDisabled: false,
      namesByType: {
        block: "Block",
        elements: "Block",
        intnet: "Intention",
        flow: "Flow",
        intent: "Intention",
        entry: "Einstieg",
        undefined: "Block",
        condition: "Bedingung",
        delay: "Verzögerung",
        split: "A/B-Split",
        note: "Notiz",
        code: "Code",
      },
      panelHeight: 0,
    };
  },
  mounted() {
    window.addEventListener("keydown", this.onKeyDown, false);
  },
  unmounted() {
    window.removeEventListener("keydown", this.onKeyDown, false);
  },
  watch: {
    node: {
      immediate: true,
      handler(node: GraphNode<IBlock>) {
        if (!node) return;
        const data = node.data;
        this.block = data;
        if (data) {
          switch (data.type) {
            case "entry":
              this.copyDisabled = true;
              this.deleteDisabled = true;
              break;
            case "intent":
            case "delay":
            case "note":
            case "condition":
            case "split":
            case "flow":
              this.copyDisabled = true;
              this.deleteDisabled = false;
              break;
            default:
              this.copyDisabled = false;
              this.deleteDisabled = false;
              break;
          }
          if (data.id == "entry") {
            this.copyDisabled = true;
            this.deleteDisabled = true;
          }
        }
        this.showOverlay = false;
        if (data) {
          this.name = data.name || "";
          this.description = data.description || "";
        }
      },
    },
  },
  computed: {
    type(): string {
      return this.node?.type || "block";
    },
    flowId(): string | null {
      return (this.$route.params.flowId as string) || null;
    },
  },
  methods: {
    onScroll(event: WheelEvent) {
      const target = event.target as HTMLElement;
      this.scrollTop = target.scrollTop > 0;
      this.showName = target.scrollTop > 50 && this.name.length > 0;
      this.scrollBottom = target.scrollTop < target.scrollHeight - 500;
      this.panelHeight = target.scrollHeight;
    },
    cancel() {
      this.$emit("close");
    },
    close() {
      this.$emit("close");
      this.save();
    },
    async save() {
      try {
        this.$emit("close");
        const content = this.$refs.content as IFlowPanelContent;
        if (content && this.flowId)
          await content.save(this.flowId, {
            name: this.name,
            description: this.description,
          });
        if (this.block) {
          this.block.name = this.name;
          this.block.description = this.description || "";
        }
        this.$emit("update:node", {
          ...this.node,
          data: this.block,
        });
      } catch (err) {
        console.error("error: ", err);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Konnte nicht gespeichert werden",
        });
      }
    },
    async deleteNode() {
      if (!this.flowId || !this.block?.id) return;
      this.$emit("close");
      this.$emit("delete");
      try {
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "info",
          content: "Schritt wird gelöscht...",
        });
        await deleteFlowStep(this.flowId, this.block);
        // await new Block(this.flowId, this.block.id).delete();
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "success",
          content: "Schritt gelöscht",
        });
      } catch (err) {
        console.error("error: ", err);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Schritt konnte nicht gelöscht werden",
        });
      }
    },
    async duplicateNode() {
      // TODO
    },
    onKeyDown(event: KeyboardEvent) {
      if (event.key === "Escape") this.cancel();
    },
  },
});
