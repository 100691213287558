import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputActions = _resolveComponent("InputActions")!

  return (_openBlock(), _createElementBlock("div", {
    class: "input-actions-wrapper",
    onFocusCapture: _cache[0] || (_cache[0] = ($event: any) => (_ctx.focus = true)),
    onBlurCapture: _cache[1] || (_cache[1] = ($event: any) => (_ctx.focus = false)),
    ref: "wrapper"
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.focus)
      ? (_openBlock(), _createBlock(_component_InputActions, {
          key: 0,
          target: _ctx.target,
          content: _ctx.content,
          "max-characters": _ctx.maxCharacters,
          translate: _ctx.translate
        }, null, 8, ["target", "content", "max-characters", "translate"]))
      : _createCommentVNode("", true)
  ], 544))
}