
import { defineComponent } from "vue";
import { MdCard } from "@/components/md/MdCard";
import { MdButton } from "@/components/md/MdButton";
import { useStore } from "@/store";
import { BotStore } from "@/store/constants";
import { MdProgressIndicatorCircular } from "@/components/md/MdProgressIndicator";
import { IModel } from "@chatcaptain/types/chatbot";
import { startTraining } from "@/api/bot/training";

interface ModelDisplayData {
  title: string;
  message?: string;
  icon: string;
  mode: "neutral" | "success" | "warning" | "error";
}

export default defineComponent({
  name: "BotTrainingModels",
  components: {
    MdCard,
    MdButton,
    MdProgressIndicatorCircular,
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  computed: {
    languages(): string[] {
      return this.store.getters[BotStore.Getters.GET_LANGUAGES];
    },
    models(): IModel[] {
      return this.store.getters[BotStore.Getters.GET_MODELS];
    },
    trainingRequired(): boolean {
      return this.models.some((model) => model.state == "untrained");
    },
  },
  methods: {
    getLanguageName(language: string): string {
      return this.store.getters[BotStore.Getters.GET_LANGUAGE_NAME](language);
    },
    getModelData(model: IModel): ModelDisplayData {
      switch (model.state) {
        case "done":
          return {
            title: "Training abgeschlossen",
            icon: "check_circle",
            mode: "success",
          };
        case "untrained":
          return {
            title: "Training notwendig",
            icon: "model_training",
            mode: "neutral",
          };
        case "ongoing":
          return {
            title: "Training läuft",
            icon: "cached",
            mode: "neutral",
          };
        case "queued":
          return {
            title: "In der Warteschlange",
            icon: "schedule",
            mode: "neutral",
          };
        case "publishing":
        case "reviewing":
          return {
            title: "Wird überprüft",
            icon: "schedule",
            mode: "neutral",
          };
        case "failed":
          return {
            title: "Training fehlgeschlagen",
            icon: "error",
            mode: "error",
          };
        default:
          return {
            title: "Keine Daten verfügbar",
            icon: "error",
            mode: "error",
          };
      }
    },
    async startTraining(model: IModel) {
      model.state = "queued";
      await startTraining(model.app_id);
    },
    startTrainingForEveryModel() {
      this.models.forEach((model) => {
        if (model.state == "untrained") {
          this.startTraining(model);
        }
      });
    },
  },
});
