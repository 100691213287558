
import { defineComponent } from "vue";
import { MdOverlay, MdOverlayComponentMixin } from "@/components/md/MdOverlay";
import {
  IMdInput,
  MdInputField,
  MdSelect,
  MdTextarea,
} from "@/components/md/MdInput";
import { MdButton } from "@/components/md/MdButton";
import { WhatsappTemplateNameValidator } from "@/classes/validators/WhatsappTemplateNameValidator";
import { IMenuItem } from "@/components/md/MdMenu";
import { MdTooltip } from "@/components/md/MdTooltip";
import { TransitionSlide } from "@/components/cc/Transition";
import WhatsappTemplateCallToAction from "@/components/bot/WhatsappTemplateCallToAction.vue";
import {
  IWhatsAppRequestTemplate,
  IWhatsAppTranslationsButtonTemplate,
  IWhatsAppTranslationsRequestTemplate,
} from "@/types/WhatsappTemplate";
import { useStore } from "@/store";
import { BotStore } from "@/store/constants";
import { createWhatsappTemplate } from "@/api/bot/template/createWhatsapp";
import { Validator } from "@/classes/validators/Validator";
import { InputActionsWrapper } from "@/components/cc/InputActions";

interface IActionButton {
  data: IActionButtonData;
  component?: "whatsapp-template-call-to-action" | "";
}

interface IActionButtonData {
  actionType: "URL" | "PHONE_NUMBER";
  text: string;
  urlType: "DYNAMIC" | "STATIC";
  url: string;
}

interface IQuickReply {
  text: string;
}

class WhatsappTemplateContentValidator extends Validator {
  protected validate(): boolean {
    this.errorMessage = "Dieses Feld muss ausgefüllt werden.";
    const textWithoutSpaces = this.value
      .toString()
      .replaceAll("\n", "")
      .replaceAll(" ", "");
    return this.value.toString().length >= 1 && textWithoutSpaces.length >= 1;
  }
}

export default defineComponent({
  name: "WhatsappTemplateCreateOverlay",
  emits: ["close"],
  mixins: [MdOverlayComponentMixin],
  components: {
    MdOverlay,
    MdInputField,
    MdButton,
    MdSelect,
    MdTooltip,
    TransitionSlide,
    WhatsappTemplateCallToAction,
    MdTextarea,
    InputActionsWrapper,
  },
  data() {
    return {
      name: "" as string,
      header: "" as string,
      content: "" as string,
      footer: "" as string,
      category: "PAYMENT_UPDATE" as string,
      controlType: "none" as string,
      headerType: "none" as string,
      nameValidator: new WhatsappTemplateNameValidator(true),
      contentValidator: new WhatsappTemplateContentValidator(true),

      actionButtons: [
        {
          component: "whatsapp-template-call-to-action",
          data: { actionType: "URL", text: "", urlType: "DYNAMIC", url: "" },
        },
      ] as IActionButton[],
      quickReplies: [
        {
          text: "",
        },
      ] as IQuickReply[],
    };
  },
  setup() {
    const store = useStore();
    return { store };
  },
  methods: {
    async addTemplate() {
      this.setLoading(true);
      if (!this.isFormValid) return;
      const buttons: IWhatsAppTranslationsButtonTemplate[] = [];

      switch (this.controlType) {
        case "action":
          this.actionButtons.forEach((button) => {
            if (button.data.text !== "" && button.data.url !== "") {
              buttons.push({
                type: button.data.actionType,
                text: button.data.text,
                url: button.data.url,
              });
            }
          });
          break;
        case "quick_reply":
          this.quickReplies.forEach((button) => {
            // TODO quick reply button kp wie die aussehen von der struktur
            if (button.text !== "") {
              buttons.push({
                type: "QUICK_REPLY",
                text: button.text,
              });
            }
          });
          break;
      }

      const translations: IWhatsAppTranslationsRequestTemplate = {
        de: [
          {
            type: "BODY",
            text: this.content,
          },
          ...(this.header.length >= 1
            ? [
                {
                  type: "HEADER",
                  format: this.headerType,
                  text: this.header,
                },
              ]
            : []),
          ...(this.footer.length >= 1
            ? [
                {
                  type: "FOOTER",
                  text: this.footer,
                },
              ]
            : []),
          ...(buttons.length >= 1
            ? [
                {
                  type: "BUTTONS",
                  buttons: buttons,
                },
              ]
            : []),
        ],
      };
      const template: IWhatsAppRequestTemplate = {
        name: this.name,
        category: this.category,
        translations,
      };
      const chatbotId = this.store.getters[BotStore.Getters.GET_ID];
      const channelId = this.store.getters[BotStore.Getters.GET_TARGET_CHANNEL];
      if (!channelId) return;
      const result = await createWhatsappTemplate(
        chatbotId,
        channelId.id as string,
        template
      );
      this.setLoading(false);
      this.close();
      this.$emit("close");
      console.log(result);
    },
    addVariable() {
      this.content += " {{" + (this.varCount + 1) + "}}";
    },
    addActionButton() {
      this.actionButtons.push({
        component: "whatsapp-template-call-to-action",
        data: { actionType: "URL", text: "", urlType: "DYNAMIC", url: "" },
      });
    },
    addQuickReply() {
      this.quickReplies.push({ text: "" });
    },
  },
  computed: {
    actions() {
      return this.actionButtons;
    },
    nameInput(): IMdInput {
      return this.$refs.nameInput as IMdInput;
    },
    isFormValid(): boolean {
      return this.nameValidator.isValid() && this.contentValidator.isValid();
    },
    varCount(): number {
      const res = this.content.match(/{{\d}}/g);
      return res ? res.length : 0;
    },
    categories() {
      return [
        {
          label: "Transaktion",
          value: "PAYMENT_UPDATE", // TRANSACTIONAL
          icon: "receipt_long",
        },
        {
          label: "Einmal-Passwort",
          value: "OTP",
          icon: "password",
        },
        {
          label: "Marketing",
          value: "ACCOUNT_UPDATE", // MARKETING
          icon: "bar_chart",
        },
      ] as IMenuItem[];
    },
    controlTypes() {
      return [
        {
          label: "Keine",
          value: "none",
          icon: "block",
        },
        {
          label: "Schnellantwort",
          value: "quick_reply",
          icon: "quickreply",
        },
        {
          label: "Call to action",
          value: "action",
          icon: "call_to_action",
        },
      ] as IMenuItem[];
    },
    headerTypes() {
      return [
        {
          label: "Kein Header",
          value: "none",
          icon: "block",
        },
        {
          label: "Text",
          value: "text",
          icon: "subject",
        },
      ] as IMenuItem[];
    },
  },
});
