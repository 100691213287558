import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "languages" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_button = _resolveComponent("md-button")!
  const _component_view_header = _resolveComponent("view-header")!
  const _component_md_table_column = _resolveComponent("md-table-column")!
  const _component_md_table = _resolveComponent("md-table")!
  const _component_settings_languages_add_modal = _resolveComponent("settings-languages-add-modal")!
  const _component_settings_languages_delete_modal = _resolveComponent("settings-languages-delete-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_view_header, {
      title: "Sprachen",
      description: "Dies sind alle aktivierten Sprachen für deinen Chatbot."
    }, {
      default: _withCtx(() => [
        _createVNode(_component_md_button, {
          variant: "filled",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addLanguageModal.open()))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Sprache hinzufügen")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_md_table, {
      rows: _ctx.rows,
      onButtonClick: _ctx.tableButtonClick
    }, {
      default: _withCtx(() => [
        _createVNode(_component_md_table_column, {
          title: "Sprache",
          display: "name"
        }),
        _createVNode(_component_md_table_column, {
          title: "Abkürzung",
          display: "code"
        }),
        _createVNode(_component_md_table_column, {
          title: "",
          display: "default",
          type: "badge"
        }),
        _createVNode(_component_md_table_column, {
          title: "",
          display: "delete",
          type: "button"
        })
      ]),
      _: 1
    }, 8, ["rows", "onButtonClick"]),
    _createVNode(_component_settings_languages_add_modal, { ref: "addLanguageModal" }, null, 512),
    _createVNode(_component_settings_languages_delete_modal, {
      ref: "deleteLanguageModal",
      language: _ctx.targetLanguage
    }, null, 8, ["language"])
  ]))
}