
import { defineComponent } from "vue";
import { MdModal, MdModalComponentMixin } from "@/components/md/MdDialog";
import { MdButton } from "@/components/md/MdButton";
import { useStore } from "@/store";
import { BotStore, RootStore } from "@/store/constants";
import { MdInputField } from "@/components/md/MdInput";
import { InputType, InputValidator } from "@/classes/InputValidator";
import { MdAlert } from "@/components/md/MdAlert";
import { createFaq } from "@/api/bot/faqs";

export default defineComponent({
  name: "FaqCreateModal",
  mixins: [MdModalComponentMixin],
  components: {
    MdModal,
    MdButton,
    MdInputField,
    MdAlert,
  },
  data() {
    return {
      name: "",
    };
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  computed: {
    nameInput(): typeof MdInputField {
      return this.$refs.nameInput as typeof MdInputField;
    },
    nameValidator(): InputValidator {
      return new InputValidator(this.name, InputType.TEXT, true);
    },
  },
  methods: {
    async createFaqPair() {
      this.nameInput.validate();
      if (!this.nameValidator.isValid()) return;
      this.setLoadingState(true);
      try {
        const faqId = await createFaq(this.name);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "success",
          content: "FAQ-Paar hinzugefügt",
        });
        this.store.dispatch(BotStore.Actions.LOAD_FAQS);
        this.$router.push({
          name: "BotFaq",
          params: {
            faqId,
          },
        });
      } catch (err) {
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "FAQ-Paar konnte nicht hinzugefügt werden",
        });
      }
      this.name = "";
      this.setLoadingState(false);
      this.close();
    },
  },
});
