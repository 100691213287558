
import { defineComponent, PropType } from "vue";
import { IBlock, ICondition, IConditionLine } from "@chatcaptain/types/chatbot";
import FlowPanelContentMixin, {
  ISaveParams,
} from "@/components/bot/FlowPanelContentMixin";
import { Block } from "@/classes/Block";
import { MdCard } from "@/components/md/MdCard";
import { IMenuItem } from "../md/MdMenu";
import {
  MdSelect,
  MdContentEditable,
  MdVariableInputField,
} from "@/components/md/MdInput";
import { MdButton } from "@/components/md/MdButton";
import { MdTooltip } from "@/components/md/MdTooltip";
import VariablePicker from "@/components/bot/VariablePicker.vue";
import { useStore } from "@/store";
import { BotStore } from "@/store/constants";

export default defineComponent({
  name: "FlowPanelContentCondition",
  mixins: [FlowPanelContentMixin],
  components: {
    MdCard,
    MdSelect,
    MdContentEditable,
    MdVariableInputField,
    MdButton,
    MdTooltip,
    VariablePicker,
  },
  props: {
    data: {
      type: Object as PropType<IBlock>,
      required: true,
    },
  },
  data() {
    return {
      value: "",
      operator: "equals",
      block: {} as ICondition,
      index: 0,
      pickerX: 0,
      pickerY: 0,
      showPicker: false,
      conditions: {} as { [key: number]: IConditionLine[] },
      operators: [
        {
          label: "gleich (==)",
          value: "equals",
        },
        {
          label: "ungleich (!=)",
          value: "not_equals",
        },
        {
          label: "größer als (>)",
          value: "greater",
        },
        {
          label: "kleiner als (<)",
          value: "less",
        },
        {
          label: "größer oder gleich (>=)",
          value: "greater_or_equals",
        },
        {
          label: "kleiner oder gleich (<=)",
          value: "less_or_equals",
        },
        {
          label: "leer",
          value: "is_empty",
        },
        {
          label: "nicht leer",
          value: "is_not_empty",
        },
      ] as IMenuItem[],
    };
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  watch: {
    data: {
      immediate: true,
      handler(val: ICondition) {
        this.block = val;
        this.conditions = val.conditions || [
          [
            {
              variable: "",
              operator: "equals",
              value: "",
              group: 0,
            },
          ],
        ];
      },
    },
  },
  methods: {
    onVariableInputFocus(event: FocusEvent) {
      const target = event.target as HTMLElement;
      const pos = target.getBoundingClientRect();
      const panel = target.closest(".flow-panel");
      const posPanel = panel?.getBoundingClientRect();

      this.pickerX = 32;
      this.pickerY = (posPanel?.height || 2) / 2 - pos.height / 2;

      this.showPicker = true;
    },
    getVariableLabel(variable: string): string {
      return (
        this.store.getters[BotStore.Getters.GET_PICKER_VARIABLE](variable)
          ?.label || ""
      );
    },
    addOr(groupIndex: number) {
      this.conditions[groupIndex].push({
        variable: "",
        type: "equals",
        value: "",
        group: groupIndex,
      });
    },
    deleteOr(groupIndex: number) {
      delete this.conditions[groupIndex];
      Object.keys(this.conditions).forEach((key, index) => {
        const lines = this.conditions[key];
        delete this.conditions[key];
        lines.forEach((line) => {
          line.group = index;
        });
        this.conditions[index] = lines;
      });
      console.log(this.conditions);
    },
    addAnd() {
      const nextIndex = Object.keys(this.conditions).length;
      this.conditions[nextIndex] = [
        {
          variable: "",
          type: "equals",
          value: "",
          group: nextIndex,
        },
      ];
    },
    async save(flowId: string, params?: ISaveParams) {
      if (!this.block.id) return;
      const block = new Block(flowId, this.block.id);
      if (this.block)
        await block.update({
          name: params?.name,
          description: params?.description || "",
          conditions: this.conditions,
        });
      this.block.conditions = this.conditions;
      this.$emit("update:data", this.block);
    },
  },
});
