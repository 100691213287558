
import { defineComponent } from "vue";

export default defineComponent({
  name: "FlowNodeWrapper",
  props: {
    name: String,
    description: String,
  },
});
