
import { defineComponent, PropType } from "vue";
import { IBlock, IDelay } from "@chatcaptain/types/chatbot";
import FlowPanelContentMixin, {
  ISaveParams,
} from "@/components/bot/FlowPanelContentMixin";
import { Block } from "@/classes/Block";
import { MdInputField } from "@/components/md/MdInput";
import { MdHint } from "@/components/md/MdHint";
import { useStore } from "@/store";
import { RootStore } from "@/store/constants";

export default defineComponent({
  name: "FlowPanelContentDelay",
  mixins: [FlowPanelContentMixin],
  components: {
    MdInputField,
    MdHint,
  },
  props: {
    data: {
      type: Object as PropType<IBlock>,
      required: true,
    },
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  data() {
    return {
      block: {} as IDelay,
      index: 0,
      delay: {
        seconds: 0,
        minutes: 0,
        hours: 0,
        days: 0,
      },
    };
  },
  watch: {
    data: {
      immediate: true,
      handler(val: IDelay) {
        this.block = val;
        let delay = val.delay;
        while (delay >= 24 * 60 * 60) {
          this.delay.days++;
          delay -= 24 * 60 * 60;
        }
        while (delay >= 60 * 60) {
          this.delay.hours++;
          delay -= 60 * 60;
        }
        while (delay >= 60) {
          this.delay.minutes++;
          delay -= 60;
        }
        this.delay.seconds = delay;
      },
    },
  },
  methods: {
    async save(flowId: string, params?: ISaveParams) {
      if (!this.block.id) return;
      const block = new Block(flowId, this.block.id);
      const delay =
        parseInt(this.delay.days.toString() || "0") * 24 * 60 * 60 +
        parseInt(this.delay.hours.toString() || "0") * 60 * 60 +
        parseInt(this.delay.minutes.toString() || "0") * 60 +
        parseInt(this.delay.seconds.toString() || "0");
      if (delay > 30 * 24 * 60 * 60) {
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Darf nicht größer als 30 Tage sein",
        });
        return;
      }
      if (this.block)
        await block.update({
          name: params?.name,
          description: params?.description || "",
          delay_type: "seconds",
          delay,
        } as IDelay);
      this.block.delay_type = "seconds";
      this.block.delay = delay;
      this.$emit("update:data", this.block);
    },
  },
});
