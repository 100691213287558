import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_content_editable = _resolveComponent("md-content-editable")!
  const _component_input_actions_wrapper = _resolveComponent("input-actions-wrapper")!
  const _component_flow_panel_element_wrapper = _resolveComponent("flow-panel-element-wrapper")!

  return (_openBlock(), _createBlock(_component_flow_panel_element_wrapper, {
    icon: "textsms",
    name: "Textnachricht",
    onDelete: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', null)))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_input_actions_wrapper, {
        maxCharacters: 1000,
        translate: true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_md_content_editable, {
            modelValue: _ctx.message[_ctx.language],
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.message[_ctx.language]) = $event)),
            text: _ctx.message[_ctx.language],
            "onUpdate:text": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.message[_ctx.language]) = $event)),
            rows: 1,
            error: false,
            ref: "input",
            "max-characters": 1000
          }, null, 8, ["modelValue", "text"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}