import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flows" }
const _hoisted_2 = { class: "content-wrapper with-nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_table_column = _resolveComponent("md-table-column")!
  const _component_md_table = _resolveComponent("md-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_md_table, {
        rows: _ctx.rows,
        clickable: true,
        onClick: _ctx.rowClick
      }, {
        default: _withCtx(() => [
          _createVNode(_component_md_table_column, {
            title: "Name",
            display: "name",
            html: true
          }),
          _createVNode(_component_md_table_column, {
            title: "Erstellt",
            display: "created"
          }),
          _createVNode(_component_md_table_column, {
            title: "Zuletzt geändert",
            display: "updated"
          }),
          _createVNode(_component_md_table_column, {
            title: "",
            display: "delete",
            type: "button"
          })
        ]),
        _: 1
      }, 8, ["rows", "onClick"])
    ])
  ]))
}