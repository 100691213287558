import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5399b0f9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "condition-wrapper" }
const _hoisted_2 = { class: "condition-group" }
const _hoisted_3 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_variable_input_field = _resolveComponent("md-variable-input-field")!
  const _component_md_select = _resolveComponent("md-select")!
  const _component_md_content_editable = _resolveComponent("md-content-editable")!
  const _component_md_button = _resolveComponent("md-button")!
  const _component_md_tooltip = _resolveComponent("md-tooltip")!
  const _component_md_card = _resolveComponent("md-card")!
  const _component_variable_picker = _resolveComponent("variable-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.conditions, (group, k) => {
      return (_openBlock(), _createBlock(_component_md_card, {
        variant: "outlined",
        key: k
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.conditions[k], (condition, k2) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "condition",
                key: k2
              }, [
                _createVNode(_component_md_variable_input_field, {
                  modelValue: condition.variable,
                  "onUpdate:modelValue": ($event: any) => ((condition.variable) = $event),
                  label: "Variable",
                  size: "small",
                  error: false,
                  onFocus: _ctx.onVariableInputFocus,
                  offset: { x: 36, y: 0 }
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onFocus"]),
                _createVNode(_component_md_select, {
                  label: "Operator",
                  modelValue: condition.type,
                  "onUpdate:modelValue": ($event: any) => ((condition.type) = $event),
                  items: _ctx.operators,
                  size: "small",
                  error: false
                }, null, 8, ["modelValue", "onUpdate:modelValue", "items"]),
                _createVNode(_component_md_content_editable, {
                  modelValue: condition.value,
                  "onUpdate:modelValue": ($event: any) => ((condition.value) = $event),
                  label: "Wert",
                  size: "small",
                  error: false
                }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                _createVNode(_component_md_button, {
                  variant: "icon",
                  icon: "cancel",
                  grey: "",
                  onClick: ($event: any) => (_ctx.conditions[k].splice(k2, 1))
                }, null, 8, ["onClick"])
              ]))
            }), 128)),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_md_button, {
                class: "add-and",
                variant: "text",
                icon: "add",
                onClick: ($event: any) => (_ctx.addOr(k))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("ODER")
                ]),
                _: 2
              }, 1032, ["onClick"]),
              _createVNode(_component_md_tooltip, { text: "Löschen" }, {
                default: _withCtx(() => [
                  _createVNode(_component_md_button, {
                    icon: "delete_forever",
                    variant: "icon",
                    grey: "",
                    onClick: ($event: any) => (_ctx.deleteOr(k))
                  }, null, 8, ["onClick"])
                ]),
                _: 2
              }, 1024)
            ])
          ])
        ]),
        _: 2
      }, 1024))
    }), 128)),
    _createVNode(_component_md_button, {
      class: "add-or",
      variant: "outlined",
      icon: "add",
      onClick: _ctx.addAnd
    }, {
      default: _withCtx(() => [
        _createTextVNode("UND Bedingung")
      ]),
      _: 1
    }, 8, ["onClick"]),
    (_ctx.showPicker)
      ? (_openBlock(), _createBlock(_component_variable_picker, {
          key: 0,
          x: _ctx.pickerX,
          y: _ctx.pickerY,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPicker = false)),
          type: "Variable"
        }, null, 8, ["x", "y"]))
      : _createCommentVNode("", true)
  ]))
}