
import { defineComponent } from "vue";
import {
  IRow,
  IRowButton,
  MdTable,
  MdTableColumn,
} from "@/components/md/MdTable";
import { useStore } from "@/store";
import { BotStore } from "@/store/constants";
import { IIntent } from "@chatcaptain/types/chatbot";
import { EmptyHint } from "@/components/cc/EmptyHint";
import { MdButton } from "@/components/md/MdButton";
import { MdFabExtended } from "@/components/md/MdFab";
import FaqCreateModal from "@/components/bot/FaqCreateModal.vue";
import FaqDeleteModal from "@/components/bot/FaqDeleteModal.vue";
import { IMdModal } from "@/components/md/MdDialog";

export default defineComponent({
  name: "Faqs",
  components: {
    MdTable,
    MdTableColumn,
    EmptyHint,
    MdButton,
    MdFabExtended,
    FaqCreateModal,
    FaqDeleteModal,
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  data() {
    return {
      isLoading: true,
      targetFaq: {} as IIntent,
      deleteButton: {
        icon: "delete",
        tooltip: "Löschen",
      } as IRowButton,
    };
  },
  mounted() {
    this.store.dispatch(BotStore.Actions.LOAD_FAQS);
  },
  watch: {
    faqs() {
      this.isLoading = false;
    },
  },
  computed: {
    faqs(): IIntent[] {
      return this.store.getters[BotStore.Getters.GET_FAQS];
    },
    rows(): IRow[] {
      const rows: IRow[] = [];
      this.faqs.forEach((faq) => {
        rows.push({
          id: faq.id,
          name: faq.name,
          delete: this.deleteButton,
          created: this.getFormattedDate(faq.created.toDate()),
          updated: this.getFormattedDate(faq.updated.toDate()),
          faq,
        });
      });
      return rows;
    },
  },
  methods: {
    getFormattedDate(date: Date): string {
      return (
        ("0" + date.getDate()).slice(-2) +
        "." +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "." +
        date.getFullYear()
      );
    },
    goToFaq(intent: IRow) {
      this.$router.push({
        name: "BotFaq",
        params: {
          faqId: intent.id,
        },
      });
    },
    handleTableButtonClick(label: string, row: IRow) {
      if (label == "delete") {
        (this.$refs.deleteModal as IMdModal).open();
        this.targetFaq = row.faq;
      }
    },
  },
});
