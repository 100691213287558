
import { defineComponent } from "vue";
import {
  IRow,
  IRowButton,
  MdTable,
  MdTableColumn,
} from "@/components/md/MdTable";
import { useStore } from "@/store";
import { BotStore } from "@/store/constants";
import { IFlow } from "@chatcaptain/types/chatbot";

export default defineComponent({
  name: "Flows",
  components: {
    MdTable,
    MdTableColumn,
  },
  setup() {
    const store = useStore();
    return { store };
  },
  data() {
    return {
      deleteButton: {
        icon: "delete",
        tooltip: "Löschen",
      } as IRowButton,
    };
  },
  mounted() {
    this.store.dispatch(BotStore.Actions.LOAD_FLOWS);
  },
  computed: {
    flows(): IFlow[] {
      return this.store.getters[BotStore.Getters.GET_FLOWS];
    },
    rows(): IRow[] {
      const rows: IRow[] = [];
      interface IFlowDepth extends IFlow {
        depth: number;
      }
      const flowsWithDepth: IFlowDepth[] = [];
      this.flows.forEach((flow) => {
        flowsWithDepth.push({
          ...flow,
          depth: this.getDepth(flow),
        });
      });
      const entryFlow = this.flows.find((flow) => flow.id === "default");
      if (entryFlow) {
        rows.push(this.flowToRow(entryFlow));
        const childs = this.getChilds(entryFlow, []);
        childs.forEach((child) => {
          rows.push(this.flowToRow(child));
        });
      }
      return rows;
    },
    flowsById(): { [key: string]: IFlow } {
      const flows = {};
      this.flows.forEach((flow) => {
        if (flow.id) flows[flow.id] = flow;
      });
      return flows;
    },
  },
  methods: {
    getFormattedDate(date: Date): string {
      return (
        ("0" + date.getDate()).slice(-2) +
        "." +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "." +
        date.getFullYear()
      );
    },
    rowClick(row: IRow) {
      this.$router.push({
        name: "BotFlow",
        params: {
          flowId: row.id,
        },
      });
    },
    getDepth(flow: IFlow): number {
      if (flow && flow.parent && flow.parent.flow_id)
        return this.getDepth(this.flowsById[flow.parent.flow_id]) + 1;
      return 0;
    },
    getChilds(flow: IFlow, _childs: IFlow[]): IFlow[] {
      const childs: IFlow[] = [];
      this.flows.forEach((child) => {
        if (child.parent && child.parent.flow_id == flow.id) {
          childs.push(child);
          childs.push(...this.getChilds(child, childs));
        }
      });
      return childs;
    },
    flowToRow(flow: IFlow): IRow {
      let spacer = "";
      for (let i = 0; i < this.getDepth(flow); i++)
        spacer += "&nbsp;&nbsp;&nbsp;&nbsp;";
      return {
        id: flow.id,
        name: spacer + flow.name,
        delete: this.deleteButton,
        created: this.getFormattedDate(flow.created.toDate()),
        updated: this.getFormattedDate(flow.updated.toDate()),
        depth: this.getDepth(flow),
      };
    },
  },
});
