
import { defineComponent, PropType } from "vue";
import { IStepFlow } from "@chatcaptain/types/chatbot";
import FlowPanelContentMixin, {
  ISaveParams,
} from "@/components/bot/FlowPanelContentMixin";
import { Block } from "@/classes/Block";
import { MdButton } from "@/components/md/MdButton";
import { MdHint } from "@/components/md/MdHint";
import { updateFlow } from "@/api/bot/flow/flow";

export default defineComponent({
  name: "FlowPanelContentFlow",
  mixins: [FlowPanelContentMixin],
  components: {
    MdButton,
    MdHint,
  },
  props: {
    data: {
      type: Object as PropType<IStepFlow>,
      required: true,
    },
  },
  data() {
    return {
      block: {} as IStepFlow,
      index: 0,
    };
  },
  watch: {
    data: {
      immediate: true,
      handler(val: IStepFlow) {
        this.block = val;
      },
    },
  },
  methods: {
    openSubFlow() {
      this.$router.push({
        name: "BotFlow",
        params: {
          flowId: this.block.flow_id,
        },
      });
    },
    async save(flowId: string, params?: ISaveParams) {
      if (!this.block.id) return;
      const block = new Block(flowId, this.block.id);
      if (this.block)
        await block.update({
          name: params?.name,
          description: params?.description || "",
        });
      const flowData = this.block as IStepFlow;
      if (flowData.flow_id && params)
        await updateFlow(flowData.flow_id, params.name);
      this.$emit("update:data", this.block);
    },
  },
});
