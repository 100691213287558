import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55491750"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "buttons" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_button = _resolveComponent("md-button")!
  const _component_VueDraggableNext = _resolveComponent("VueDraggableNext")!
  const _component_md_content_editable = _resolveComponent("md-content-editable")!
  const _component_input_actions_wrapper = _resolveComponent("input-actions-wrapper")!
  const _component_md_tab = _resolveComponent("md-tab")!
  const _component_md_tabs_inline = _resolveComponent("md-tabs-inline")!
  const _component_md_card = _resolveComponent("md-card")!
  const _component_flow_panel_element_wrapper = _resolveComponent("flow-panel-element-wrapper")!

  return (_openBlock(), _createBlock(_component_flow_panel_element_wrapper, {
    icon: "call_split",
    name: "Buttons",
    onDelete: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$emit('update:modelValue', null)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_VueDraggableNext, {
          list: _ctx.buttons,
          handle: ".drag"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button, k) => {
              return (_openBlock(), _createElementBlock("div", {
                key: k,
                class: "button"
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["clickable", { 'no-url': button.type != 'url' }]),
                  onClick: ($event: any) => (_ctx.openEditor($event, button, k))
                }, [
                  _createElementVNode("h4", null, _toDisplayString(button.name[_ctx.language]), 1),
                  (button.type == 'url')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(button.value), 1))
                    : _createCommentVNode("", true)
                ], 10, _hoisted_2),
                _createVNode(_component_md_button, {
                  class: "drag",
                  variant: "icon",
                  mode: "icon_small",
                  icon: "drag_indicator",
                  grey: ""
                }),
                _createVNode(_component_md_button, {
                  class: "delete",
                  variant: "icon",
                  mode: "icon_small",
                  icon: "delete_forever",
                  grey: "",
                  onClick: ($event: any) => (_ctx.buttons.splice(k, 1))
                }, null, 8, ["onClick"])
              ]))
            }), 128))
          ]),
          _: 1
        }, 8, ["list"]),
        _createVNode(_component_md_button, {
          class: "add-button",
          variant: "outlined",
          icon: "add",
          onClick: _ctx.addButton
        }, {
          default: _withCtx(() => [
            _createTextVNode("Button hinzufügen")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_Transition, { name: "editor" }, {
          default: _withCtx(() => [
            (_ctx.showEditor)
              ? (_openBlock(), _createBlock(_component_md_card, {
                  key: 0,
                  variant: "elevated",
                  class: "button-editor",
                  style: _normalizeStyle({ top: _ctx.editorY + 'px' })
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_input_actions_wrapper, {
                      "max-characters": 20,
                      translate: true
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_md_content_editable, {
                          placeholder: "Name des Buttons",
                          align: "center",
                          modelValue: _ctx.targetButton.name[_ctx.language],
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.targetButton.name[_ctx.language]) = $event)),
                          text: _ctx.targetButton.name[_ctx.language],
                          "onUpdate:text": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.targetButton.name[_ctx.language]) = $event)),
                          rows: 1,
                          multiline: false,
                          error: false,
                          ref: "input",
                          onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.focus = true)),
                          onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.focus = false)),
                          "max-characters": 20
                        }, null, 8, ["modelValue", "text"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_md_tabs_inline, {
                      align: "center",
                      mode: "small",
                      modelValue: _ctx.targetButton.type,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.targetButton.type) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_md_tab, {
                          title: "Block",
                          name: "block",
                          icon: "redo"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_input_actions_wrapper, { "max-characters": 256 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_md_content_editable, {
                                  placeholder: "Payload (optional)",
                                  align: "center",
                                  modelValue: _ctx.targetButton.payload,
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.targetButton.payload) = $event)),
                                  text: _ctx.targetButton.payload,
                                  "onUpdate:text": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.targetButton.payload) = $event)),
                                  rows: 1,
                                  multiline: false,
                                  error: false,
                                  "max-characters": 256
                                }, null, 8, ["modelValue", "text"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_md_tab, {
                          title: "URL",
                          name: "url",
                          icon: "public"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_input_actions_wrapper, { "max-characters": 2048 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_md_content_editable, {
                                  placeholder: "URL (mit https:// oder http://)",
                                  align: "center",
                                  modelValue: _ctx.targetButtonUrl,
                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.targetButtonUrl) = $event)),
                                  text: _ctx.targetButtonUrl,
                                  "onUpdate:text": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.targetButtonUrl) = $event)),
                                  rows: 1,
                                  multiline: false,
                                  error: false,
                                  ref: "input",
                                  onFocus: _cache[8] || (_cache[8] = ($event: any) => (_ctx.focus = true)),
                                  onBlur: _cache[9] || (_cache[9] = ($event: any) => (_ctx.focus = false)),
                                  "max-characters": 2048
                                }, null, 8, ["modelValue", "text"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"]),
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_md_button, {
                        variant: "icon",
                        icon: "close",
                        grey: "",
                        onClick: _ctx.closeEditor
                      }, null, 8, ["onClick"]),
                      _createVNode(_component_md_button, {
                        variant: "filled",
                        onClick: _ctx.saveButton
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Übernehmen")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ])
                  ]),
                  _: 1
                }, 8, ["style"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}