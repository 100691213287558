
import { defineComponent, PropType } from "vue";
import { MdButton } from "@/components/md/MdButton";
import FlowPanelElementText from "@/components/bot/FlowPanelElementText.vue";
import FlowPanelElementButtons from "@/components/bot/FlowPanelElementButtons.vue";
import { IBlock, IBlockElement } from "@chatcaptain/types/chatbot";
import { VueDraggableNext } from "vue-draggable-next";
import FlowPanelContentMixin, {
  ISaveParams,
} from "@/components/bot/FlowPanelContentMixin";
import { Block } from "@/classes/Block";
import { TransitionFade } from "@/components/cc/Transition";
import FlowPanelBlockElementsList from "@/components/bot/FlowPanelBlockElementsList.vue";
import { useStore } from "@/store";
import { BotStore } from "@/store/constants";
import { getElementDefault } from "@/utils/BlockElements";
import { MdCard } from "@/components/md/MdCard";
import { MdCheckbox } from "@/components/md/MdCheckbox";
import { deleteField } from "@firebase/firestore";

export default defineComponent({
  name: "FlowPanelContentBlock",
  components: {
    MdCard,
    MdCheckbox,
    VueDraggableNext,
    MdButton,
    TransitionFade,
    FlowPanelElementText,
    FlowPanelElementButtons,
    FlowPanelBlockElementsList,
  },
  mixins: [FlowPanelContentMixin],
  props: {
    data: {
      type: Object as PropType<IBlock>,
      required: true,
    },
  },
  setup() {
    return {
      store: useStore(),
    };
  },
  data() {
    return {
      block: {} as IBlock,
      showElementsList: false,
      index: 0,
      showAdvanced: false,
      showAdvancedBlocked: false,
      userInput: false,
    };
  },
  computed: {
    defaultLanguage(): string {
      return this.store.getters[BotStore.Getters.GET_DEFAULT_LANGUAGE];
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(val: IBlock) {
        this.block = val;
        if (this.block.user_input) this.userInput = true;
      },
    },
    block: {
      deep: true,
      handler(block: IBlock) {
        if (block.elements) {
          const deleteIndex = block.elements.findIndex((el) => el == null);
          if (deleteIndex >= 0) block.elements.splice(deleteIndex, 1);
        }
      },
    },
    userInput(active: boolean) {
      if (active) {
        this.block.user_input = { target: "" };
        this.block.redirect = undefined;
      } else {
        this.block.user_input = undefined;
      }
    },
  },
  methods: {
    async save(flowId: string, params?: ISaveParams) {
      if (!this.block.id || !this.block.elements) return;
      const block = new Block(flowId, this.block.id);
      if (this.block)
        await block.update({
          elements: this.block.elements,
          name: params?.name,
          description: params?.description || "",
          user_input: this.block.user_input || null,
          redirect: this.block.redirect,
        });
      this.$emit("update:data", this.block);
    },
    toggleElements(val: boolean) {
      this.showElementsList = val;
      this.$emit("overlay", val);
    },
    addElement(type: string) {
      this.toggleElements(false);
      const element = JSON.parse(
        JSON.stringify(getElementDefault(type))
      ) as IBlockElement;
      if (this.block.elements) {
        this.block.elements.push(element);
      } else {
        this.block.elements = [element];
      }
      if (type == "buttons") this.block.redirect = undefined;
    },
    expandAdvancedSection() {
      if (!this.showAdvancedBlocked) this.showAdvanced = true;
    },
    collapseAdvancedSection() {
      if (this.showAdvanced) {
        this.showAdvanced = false;
        this.showAdvancedBlocked = true;
        setTimeout(() => {
          this.showAdvancedBlocked = false;
        }, 50);
      }
    },
  },
});
