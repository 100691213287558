import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53cc1930"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "button"
}
const _hoisted_2 = { class: "button" }
const _hoisted_3 = { class: "button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_button = _resolveComponent("md-button")!
  const _component_md_tooltip = _resolveComponent("md-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: "input-actions",
    style: _normalizeStyle({ top: _ctx.y + 'px' }),
    ref: "actions"
  }, [
    (_ctx.translate)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_md_tooltip, {
            position: "bottom",
            text: "Mehrsprachig"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_md_button, {
                variant: "icon",
                icon: "translate",
                grey: "",
                mode: "icon_small",
                disabled: true
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_md_tooltip, {
        position: "bottom",
        text: "Emojis"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_md_button, {
            variant: "icon",
            icon: "insert_emoticon",
            grey: "",
            mode: "icon_small"
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_md_tooltip, {
        position: "bottom",
        text: "Variablen"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_md_button, {
            variant: "icon",
            icon: "format_quote",
            grey: "",
            mode: "icon_small"
          })
        ]),
        _: 1
      })
    ]),
    (_ctx.maxCharacters && _ctx.remainingChracters !== false)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass(_ctx.counterClassName)
        }, _toDisplayString(_ctx.remainingChracters), 3))
      : _createCommentVNode("", true)
  ], 4))
}