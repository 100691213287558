
import { defineComponent } from "vue";
import { MdInputField, MdSelect } from "@/components/md/MdInput";
import { IMenuItem } from "@/components/md/MdMenu";
import { MdButton } from "@/components/md/MdButton";
import {
  IRow,
  IRowBadge,
  IRowButton,
  MdTable,
  MdTableColumn,
} from "@/components/md/MdTable";
import WhatsappTemplateCreateOverlay from "@/components/bot/WhatsappTemplateCreateOverlay.vue";
import { IMdOverlay } from "@/components/md/MdOverlay";
import { useStore } from "@/store";
import { BotStore } from "@/store/constants";
import { IChannel } from "@chatcaptain/types/dist/chatbot/channels";
import { Timestamp } from "firebase/firestore";
import { DateFormat } from "@/classes/DateFormat";

interface IWhatsAppTemplateTableRow {
  name: string;
  content: string | undefined;
  language: IRowBadge;
  category: string;
  updated: string;
  delete: IRowButton;
}

export default defineComponent({
  name: "WhatsappTemplate",
  components: {
    MdSelect,
    MdInputField,
    MdButton,
    MdTable,
    MdTableColumn,
    WhatsappTemplateCreateOverlay,
  },
  setup() {
    const store = useStore();
    return { store };
  },
  mounted() {
    this.store.dispatch(BotStore.Actions.LOAD_CHANNELS);
  },
  data() {
    return {
      whatsappChannel: "none" as string | number,
      items: [] as IMenuItem[],
      templateSearch: "" as string,
      deleteButton: {
        icon: "delete",
        tooltip: "Löschen",
      },
    };
  },
  watch: {
    selectItems: {
      immediate: true,
      deep: true,
      handler() {
        if (this.selectItems.length >= 2) {
          this.whatsappChannel = this.selectItems[1].value;
          const channel: IChannel | null =
            this.availableWhatsappChannels.find(
              (channel) => channel.id === this.whatsappChannel
            ) || null;
          this.store.commit(BotStore.Mutations.SET_TARGET_CHANNEL, channel);
        }
      },
    },
  },
  computed: {
    availableWhatsappChannels(): IChannel[] {
      return this.store.getters[BotStore.Getters.GET_CHANNELS];
    },
    templates(): IWhatsAppTemplateTableRow[] {
      if (this.whatsappChannel) {
        const templates =
          this.store.getters[BotStore.Getters.GET_WHATSAPP_TEMPLATES] || [];
        const result: IWhatsAppTemplateTableRow[] = [];
        templates.forEach((template) => {
          if (
            template.name
              .toLocaleLowerCase()
              .includes(this.templateSearch.toLowerCase())
          ) {
            result.push({
              name: template.name,
              content: template.translations["de"][0].text,
              category: template.category,
              language: {
                state:
                  template.translations["de"][0].status === "APPROVED"
                    ? "success"
                    : "error",
                icon: true,
                text: "Deutsch",
              } as IRowBadge,
              updated: DateFormat.formatDate(
                (template.updated as Timestamp).toDate()
              ),
              delete: this.deleteButton,
            });
          }
        });
        return result;
      }
      return [];
    },
    createTemplateOverlay(): IMdOverlay {
      return this.$refs.createTemplateOverlay as IMdOverlay;
    },
    selectItems(): IMenuItem[] {
      const items: IMenuItem[] = [];
      items.push({
        label: "Kein Channel",
        value: "none",
        icon: "close",
      });
      if (this.availableWhatsappChannels) {
        this.availableWhatsappChannels.forEach((channel) => {
          items.push({
            label: channel.name,
            value: channel.id as string,
            icon: "person",
          });
        });
      }
      return items;
    },
  },
  methods: {
    loadWhatsappTemplates() {
      this.store.dispatch(BotStore.Actions.LOAD_WHATSAPP_TEMPLATES);
    },
    onButtonClick(columnName: string, row: IRow, index: number) {
      console.log(columnName);
      console.log(index);
    },
    getDate(date: Date) {
      return (
        ("0" + date.getDate()).slice(-2) +
        "." +
        ("0" + (date.getMonth() + 1)) +
        "." +
        date.getFullYear()
      );
    },
    changeChannel() {
      const channel: IChannel | null =
        this.availableWhatsappChannels.find(
          (channel) => channel.id === this.whatsappChannel
        ) || null;
      this.store.commit(BotStore.Mutations.SET_TARGET_CHANNEL, channel);
    },
    openCreateTemplateOverlay() {
      this.createTemplateOverlay.open();
    },
  },
});
