
import { defineComponent } from "vue";
import { MdProgressIndicatorCircular } from "@/components/md/MdProgressIndicator";
import FlowNodeWrapper from "@/components/bot/FlowNodeWrapper.vue";

export default defineComponent({
  name: "FlowNodeLoader",
  components: {
    MdProgressIndicatorCircular,
    FlowNodeWrapper,
  },
  props: {
    data: Object,
  },
});
