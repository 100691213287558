
import { defineComponent, PropType } from "vue";
import { Handle } from "@braks/vue-flow";
import FlowNodeWrapper from "@/components/bot/FlowNodeWrapper.vue";
import { IBlock } from "@chatcaptain/types/chatbot";

export interface IFlowBlockProps extends IBlock {
  showHandle: boolean;
}

export default defineComponent({
  name: "FlowNodeBlock",
  components: {
    FlowNodeWrapper,
    Handle,
  },
  props: {
    data: {
      type: Object as PropType<IFlowBlockProps>,
      required: true,
      default: () => {
        return {};
      },
    },
  },
});
