import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_input_field = _resolveComponent("md-input-field")!
  const _component_md_button = _resolveComponent("md-button")!
  const _component_md_modal = _resolveComponent("md-modal")!

  return (_openBlock(), _createBlock(_component_md_modal, {
    ref: "modal",
    title: "Telegram Bot hinzufügen",
    icon: "language"
  }, {
    default: _withCtx(() => [
      _createTextVNode(" Füge deinen Telegram Bot zu ChatCaptain hinzu, damit ChatCaptain dort alle Nachrichten empfangen und beatnworten kann. ")
    ]),
    body: _withCtx(() => [
      _createVNode(_component_md_input_field, {
        label: "Bot Token",
        modelValue: _ctx.botToken,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.botToken) = $event))
      }, null, 8, ["modelValue"])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_md_button, {
        variant: "text",
        grey: "",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close()))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Abbrechen")
        ]),
        _: 1
      }),
      _createVNode(_component_md_button, {
        variant: "filled",
        onClick: _ctx.createTelegramBot
      }, {
        default: _withCtx(() => [
          _createTextVNode("Telegram Bot hinzufügen")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 512))
}