import { Validator } from "@/classes/validators/Validator";

export class WhatsappTemplateNameValidator extends Validator {
  /**
   * Validate a whatsapp template name.
   * Checks:
   * - only numbers, lowercase chars and underscores
   */
  validate(): boolean {
    this.errorMessage = "Dies ist kein gültiger Name.";
    return /^[a-z0-9_]{3,50}$/.test(this.value.toString());
  }
}
