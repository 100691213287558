
import { defineComponent, PropType } from "vue";
import { MdTooltip } from "@/components/md/MdTooltip";
import { MdButton } from "@/components/md/MdButton";

export interface IStep {
  name: string;
  type: string;
  icon?: string;
  category?: string;
  isCategory?: boolean;
  description?: string;
  _expanded?: boolean;
}

export default defineComponent({
  name: "StepsList",
  components: {
    MdTooltip,
    MdButton,
  },
  emits: ["scroll", "click"],
  props: {
    items: {
      type: Array as PropType<IStep[]>,
      required: true,
    },
    query: String,
    draggable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    searchQuery(): string | undefined {
      return this.query?.toLowerCase();
    },
    steps(): IStep[] {
      const steps: IStep[] = [];
      return this.searchQuery
        ? this.items.filter(
            (step) =>
              step.name.toLowerCase().includes(this.searchQuery || "") &&
              !step.isCategory
          )
        : this.items;
    },
  },
  methods: {
    onScroll(event: WheelEvent) {
      const target = event.target as HTMLElement;
      this.$emit("scroll", target.scrollTop);
    },
    onWrapperDragStart(event: DragEvent, type: string) {
      if (event.dataTransfer) {
        event.dataTransfer.setData("application/flow", type);
        event.dataTransfer.effectAllowed = "move";
      }
    },
    onClick(event: PointerEvent, step: IStep) {
      const target = event.target as HTMLElement;
      if (target.innerText == "expand_more") return;
      this.$emit("click", step.type);
    },
  },
});
