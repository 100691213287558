
import { defineComponent } from "vue";
import { IRowButton, MdTable, MdTableColumn } from "@/components/md/MdTable";
import { useStore } from "@/store";
import { BotStore } from "@/store/constants";
import { EmptyHint } from "@/components/cc/EmptyHint";
import { MdButton } from "@/components/md/MdButton";
import { MdCard } from "@/components/md/MdCard";
// Logos
import WebLogo from "@/assets/images/channels/web.svg";
import WhatsAppLogo from "@/assets/images/channels/whatsapp.svg";
import InstagramLogo from "@/assets/images/channels/instagram.svg";
import FacebookMessengerLogo from "@/assets/images/channels/fb-messenger.svg";
import GoogleLogo from "@/assets/images/channels/google.svg";
import TelegramLogo from "@/assets/images/channels/telegram.svg";
import { IMdModal } from "@/components/md/MdDialog";
import ChannelsTelegramCreateModal from "@/components/bot/ChannelsTelegramCreateModal.vue";

export default defineComponent({
  name: "Channels",
  components: {
    ChannelsTelegramCreateModal,
    MdTable,
    MdTableColumn,
    EmptyHint,
    MdButton,
    MdCard,
  },
  setup() {
    const store = useStore();
    return { store };
  },
  data() {
    return {
      isLoading: true,
      deleteButton: {
        icon: "delete",
        tooltip: "Löschen",
      } as IRowButton,
      channelTypes: [
        {
          name: "Web Messenger",
          description:
            "Integriere den Web Messenger direkt in deine Website und erreiche so deine Besucher.",
          logo: WebLogo,
        },
        {
          name: "WhatsApp",
          description:
            "Erreiche deine Nutzer direkt über WhatsApp und chatte noch heute mit diesen.",
          logo: WhatsAppLogo,
        },
        {
          name: "Instagram",
          description:
            "Erreiche deine Nutzer direkt über WhatsApp und chatte noch heute mit diesen.",
          logo: InstagramLogo,
        },
        {
          name: "Facebook Messenger",
          description:
            "Erreiche deine Nutzer direkt über den Facebook Messenger und chatte noch heute mit diesen.",
          logo: FacebookMessengerLogo,
        },
        {
          name: "Google Business Messages",
          description:
            "Erreiche deine Nutzer direkt über Google und chatte noch heute mit diesen.",
          logo: GoogleLogo,
        },
        {
          name: "Telegram",
          description:
            "Erreiche deine Nutzer direkt über Telegram und chatte noch heute mit diesen.",
          logo: TelegramLogo,
        },
      ],
    };
  },
  mounted() {
    this.store.dispatch(BotStore.Actions.LOAD_FAQS);
  },
  watch: {
    faqs() {
      this.isLoading = false;
    },
  },
  computed: {
    createTelegramChannaleModal(): IMdModal {
      return this.$refs.createTelegramChannaleModal as IMdModal;
    },
  },
  methods: {
    getFormattedDate(date: Date): string {
      return (
        ("0" + date.getDate()).slice(-2) +
        "." +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "." +
        date.getFullYear()
      );
    },
  },
});
