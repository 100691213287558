import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4076f93e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "checkbox" }
const _hoisted_2 = { class: "checkbox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_select = _resolveComponent("md-select")!
  const _component_md_checkbox = _resolveComponent("md-checkbox")!
  const _component_md_alert = _resolveComponent("md-alert")!
  const _component_md_button = _resolveComponent("md-button")!
  const _component_md_modal = _resolveComponent("md-modal")!

  return (_openBlock(), _createBlock(_component_md_modal, {
    ref: "modal",
    title: "Sprache hinzufügen",
    icon: "language"
  }, {
    default: _withCtx(() => [
      _createTextVNode(" Füge eine weitere Sprache hinzu, damit dein Chatbot für diese eine native Unterstützung anbieten kann. Du musst anschließend alle Trainingsdaten und Nachrichten übersetzen. ")
    ]),
    body: _withCtx(() => [
      _createVNode(_component_md_select, {
        modelValue: _ctx.language,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.language) = $event)),
        label: "Sprache auswählen",
        items: _ctx.items,
        searchable: true,
        ref: "languageInput",
        "input-validator": _ctx.languageValidator
      }, null, 8, ["modelValue", "items", "input-validator"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_md_checkbox, {
          modelValue: _ctx.translateExamples,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.translateExamples) = $event))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Trainingsdaten automatisch von " + _toDisplayString(_ctx.defaultLanguageName) + " übersetzten lassen (empfohlen) ", 1)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_md_checkbox, {
          modelValue: _ctx.translateContent,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.translateContent) = $event))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Inhalte (Antworten) automatisch von " + _toDisplayString(_ctx.defaultLanguageName) + " übersetzten lassen ", 1)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      (_ctx.translateExamples || _ctx.translateContent)
        ? (_openBlock(), _createBlock(_component_md_alert, {
            key: 0,
            icon: "info",
            variant: "info"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Durch das automatische Übersetzten werden weitere Gebühren erhoben. Diese belaufen sich auf 0,02 € pro 1.000 Zeichen. Die Kosten erscheinen auf deiner nächsten Rechnung. ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_md_button, {
        variant: "text",
        grey: "",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.close()))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Abbrechen")
        ]),
        _: 1
      }),
      _createVNode(_component_md_button, {
        variant: "filled",
        onClick: _ctx.addLanguageSubmit
      }, {
        default: _withCtx(() => [
          _createTextVNode("Sprache hinzufügen")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 512))
}