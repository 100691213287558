import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-747d3c18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "delay" }
const _hoisted_2 = { class: "inputs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_input_field = _resolveComponent("md-input-field")!
  const _component_md_hint = _resolveComponent("md-hint")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_md_input_field, {
        variant: "outlined",
        modelValue: _ctx.delay.days,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.delay.days) = $event)),
        label: "Tage",
        error: false,
        size: "small"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_md_input_field, {
        variant: "outlined",
        modelValue: _ctx.delay.hours,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.delay.hours) = $event)),
        label: "Stunden",
        error: false,
        size: "small"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_md_input_field, {
        variant: "outlined",
        modelValue: _ctx.delay.minutes,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.delay.minutes) = $event)),
        label: "Minuten",
        error: false,
        size: "small"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_md_input_field, {
        variant: "outlined",
        modelValue: _ctx.delay.seconds,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.delay.seconds) = $event)),
        label: "Sekunden",
        error: false,
        size: "small"
      }, null, 8, ["modelValue"])
    ]),
    _createVNode(_component_md_hint, { icon: "info" }, {
      default: _withCtx(() => [
        _createTextVNode("Die Gesamtdauer darf maximal 30 Tage betragen.")
      ]),
      _: 1
    }),
    _createVNode(_component_md_hint, { icon: "info" }, {
      default: _withCtx(() => [
        _createTextVNode("Die Ausführung kann nicht sekundengenau garantiert werden. In der Regel wird der nächste Schritt innerhalb von 1 bis 5 Sekunden nach der eingestellten Verzögerung ausgeführt.")
      ]),
      _: 1
    })
  ]))
}