import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6108534b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "channels" }
const _hoisted_2 = { class: "content-wrapper with-nav" }
const _hoisted_3 = { class: "add-channel-wrapper" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "actions" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_button = _resolveComponent("md-button")!
  const _component_md_card = _resolveComponent("md-card")!
  const _component_md_table_column = _resolveComponent("md-table-column")!
  const _component_md_table = _resolveComponent("md-table")!
  const _component_empty_hint = _resolveComponent("empty-hint")!
  const _component_channels_telegram_create_modal = _resolveComponent("channels-telegram-create-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.channelTypes, (channel, k) => {
          return (_openBlock(), _createBlock(_component_md_card, {
            key: k,
            variant: "outlined",
            class: "add-channel"
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: channel.logo
              }, null, 8, _hoisted_4),
              _createElementVNode("h3", null, _toDisplayString(channel.name), 1),
              _createElementVNode("p", null, _toDisplayString(channel.description), 1),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_md_button, { variant: "filled" }, {
                  default: _withCtx(() => [
                    _createTextVNode("Verbinden")
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 2
          }, 1024))
        }), 128)),
        _createVNode(_component_md_card, {
          variant: "outlined",
          class: "add-channel"
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: _ctx.channelTypes[5].logo
            }, null, 8, _hoisted_6),
            _createElementVNode("h3", null, _toDisplayString(_ctx.channelTypes[5].name), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.channelTypes[5].description), 1),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_md_button, {
                variant: "filled",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createTelegramChannaleModal.open()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Verbinden")
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      ((_ctx.rows && _ctx.rows.length > 0) || _ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_md_table, {
            key: 0,
            rows: [],
            clickable: true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_md_table_column, {
                title: "Name",
                display: "name"
              }),
              _createVNode(_component_md_table_column, {
                title: "Sprachen",
                display: "languages"
              }),
              _createVNode(_component_md_table_column, {
                title: "Erstellt",
                display: "created"
              }),
              _createVNode(_component_md_table_column, {
                title: "Zuletzt geändert",
                display: "updated"
              }),
              _createVNode(_component_md_table_column, {
                title: "",
                display: "delete",
                type: "button"
              })
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_empty_hint, {
            key: 1,
            title: "Kein FAQ-Paar hinzugefügt",
            description: "Es wurde noch kein FAQ-Paar hinzugefügt. Füge welche hinzu, um häufige Fragen noch einfacher und schneller zu beantworten."
          }, {
            default: _withCtx(() => [
              _createVNode(_component_md_button, { variant: "filled" }, {
                default: _withCtx(() => [
                  _createTextVNode("FAQ-Paar hinzufügen")
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
    ]),
    _createVNode(_component_channels_telegram_create_modal, { ref: "createTelegramChannaleModal" }, null, 512)
  ]))
}