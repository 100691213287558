import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-962e80a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "files" }
const _hoisted_2 = { class: "content-wrapper with-nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_table_column = _resolveComponent("md-table-column")!
  const _component_md_table = _resolveComponent("md-table")!
  const _component_md_button = _resolveComponent("md-button")!
  const _component_empty_hint = _resolveComponent("empty-hint")!
  const _component_md_fab_extended = _resolveComponent("md-fab-extended")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ((_ctx.rows && _ctx.rows.length > 0) || _ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_md_table, {
            key: 0,
            rows: [],
            onClick: _ctx.goToFaq,
            clickable: true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_md_table_column, {
                title: "Name",
                display: "name"
              }),
              _createVNode(_component_md_table_column, {
                title: "Sprachen",
                display: "languages"
              }),
              _createVNode(_component_md_table_column, {
                title: "Erstellt",
                display: "created"
              }),
              _createVNode(_component_md_table_column, {
                title: "Zuletzt geändert",
                display: "updated"
              }),
              _createVNode(_component_md_table_column, {
                title: "",
                display: "delete",
                type: "button"
              })
            ]),
            _: 1
          }, 8, ["onClick"]))
        : (_openBlock(), _createBlock(_component_empty_hint, {
            key: 1,
            title: "Kein FAQ-Paar hinzugefügt",
            description: "Es wurde noch kein FAQ-Paar hinzugefügt. Füge welche hinzu, um häufige Fragen noch einfacher und schneller zu beantworten."
          }, {
            default: _withCtx(() => [
              _createVNode(_component_md_button, { variant: "filled" }, {
                default: _withCtx(() => [
                  _createTextVNode("FAQ-Paar hinzufügen")
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
    ]),
    _createVNode(_component_md_fab_extended, { icon: "file_upload" }, {
      default: _withCtx(() => [
        _createTextVNode("Dateien hochladen")
      ]),
      _: 1
    })
  ]))
}