import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f4c382e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "split" }
const _hoisted_2 = { class: "inputs" }
const _hoisted_3 = { class: "mode" }
const _hoisted_4 = { class: "radio-wrapper" }
const _hoisted_5 = { class: "radio-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_input_field = _resolveComponent("md-input-field")!
  const _component_md_hint = _resolveComponent("md-hint")!
  const _component_md_alert = _resolveComponent("md-alert")!
  const _component_md_radio = _resolveComponent("md-radio")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_md_input_field, {
        variant: "outlined",
        modelValue: _ctx.exitA,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.exitA) = $event)),
        label: "Ausgang A (in %)",
        error: false,
        size: "small"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_md_input_field, {
        variant: "outlined",
        modelValue: _ctx.exitB,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.exitB) = $event)),
        label: "Ausgang B (in %)",
        error: false,
        size: "small"
      }, null, 8, ["modelValue"])
    ]),
    _createVNode(_component_md_hint, { icon: "info" }, {
      default: _withCtx(() => [
        _createTextVNode("Muss zusammen 100 % ergeben.")
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_md_alert, { variant: "neutral" }, {
        default: _withCtx(() => [
          _createTextVNode("Lege fest, ob ein Nutzer bei einem mehrmaligen Durchlauf dieses Schritts immer an die selbe Variante weitergeleitet werden soll oder ob bei jedem Durchlauf eine neue Verteilung vorgenommen wird.")
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_md_radio, {
          modelValue: _ctx.sameRedirect,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sameRedirect) = $event)),
          value: "on"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Nutzer an die selbe Variante weiterleiten")
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_md_radio, {
          modelValue: _ctx.sameRedirect,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.sameRedirect) = $event)),
          value: "off"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Bei jedem Aufruf neu verteilen")
          ]),
          _: 1
        }, 8, ["modelValue"])
      ])
    ])
  ]))
}