
import { defineComponent } from "vue";
import { MdTabs, MdTab } from "@/components/md/MdTabs";

export default defineComponent({
  name: "Training",
  components: {
    MdTabs,
    MdTab,
  },
});
