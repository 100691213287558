
import { defineComponent, PropType } from "vue";
import { MdInputField, MdSelect } from "@/components/md/MdInput";
import { IMenuItem } from "@/components/md/MdMenu";

interface IActionButton {
  text: string;
  url: string;
  actionType: string;
  urlType: string;
}

export default defineComponent({
  name: "WhatsappTemplateCallToAction",
  components: {
    MdSelect,
    MdInputField,
  },
  emits: ["update:modelValue"],
  props: { modelValue: Object as PropType<IActionButton> },
  data() {
    return {
      actionType: this.modelValue?.actionType || "",
      urlType: this.modelValue?.urlType || "",
      text: this.modelValue?.text || "",
      url: this.modelValue?.url || "",
    };
  },
  methods: {
    updateModelValue() {
      const obj: IActionButton = {
        actionType: this.actionType,
        urlType: this.urlType,
        text: this.text,
        url: this.url,
      };
      this.$emit("update:modelValue", obj);
    },
  },
  computed: {
    actionTypes() {
      return [
        {
          label: "Zur Webseite",
          value: "URL",
          icon: "public",
        },
      ] as IMenuItem[];
    },
    urlTypes() {
      return [
        {
          label: "Dynamic",
          value: "DYNAMIC",
          icon: "dynamic_feed",
        },
      ] as IMenuItem[];
    },
  },
});
