
import { defineComponent, VNode } from "vue";
import { MdInputField, MdContentEditable } from "@/components/md/MdInput";
import InputActions from "./InputActions.vue";

interface IMdInputFieldProps {
  modelValue: string;
  text: string;
  id: string;
}

export default defineComponent({
  name: "InputActionsWrapper",
  components: {
    InputActions,
  },
  props: {
    maxCharacters: Number,
    translate: Boolean,
  },
  setup(props, { slots }) {
    const defaultSlot = slots.default;
    let content = "",
      targetProps = {} as IMdInputFieldProps;
    if (defaultSlot) {
      const inputFields = defaultSlot().filter(
        (el: VNode) => el.type == MdInputField
      );
      const contentEditables = defaultSlot().filter(
        (el: VNode) => el.type == MdContentEditable
      );
      targetProps = (contentEditables[0].props ||
        inputFields[0].props) as IMdInputFieldProps;
      content = targetProps.text;
    }
    return {
      targetProps,
      content,
    };
  },
  mounted() {
    const observer = new MutationObserver(this.onChange);
    observer.observe(this.$el, {
      childList: true,
      subtree: true,
      attributes: true,
      characterData: true,
      attributeOldValue: true,
      characterDataOldValue: true,
    });
    this.observer = observer;
    this.target = this.$refs.wrapper as HTMLElement;
  },
  unmounted() {
    this.observer?.disconnect();
  },
  data() {
    return {
      focus: false,
      observer: null as MutationObserver | null,
      target: {} as HTMLElement,
    };
  },
  methods: {
    onChange() {
      const defaultSlot = this.$slots.default;
      if (defaultSlot) {
        this.target = this.$refs.wrapper as HTMLElement;
        let targetProps = {} as IMdInputFieldProps;
        const inputFields = defaultSlot().filter(
          (el: VNode) => el.type == MdInputField
        );
        const contentEditables = defaultSlot().filter(
          (el: VNode) => el.type == MdContentEditable
        );
        targetProps = (contentEditables[0].props ||
          inputFields[0].props) as IMdInputFieldProps;
        this.target = this.$refs.wrapper as HTMLElement;
        this.content = targetProps.text;
      }
    },
  },
});
